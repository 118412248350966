import { Image } from "react-bootstrap";

import mitCert from '../components/images/cert_aca/2023-08-03 Master of Information Technology.png'
import mitTrans from '../components/images/cert_aca/2023-08-02 Graduation statement.png'
import mitDean2023 from '../components/images/cert_aca/2023 Deans List Certificates PG_HIN HANG HO.png'
import mitDean2022 from '../components/images/cert_aca/2022 Deans List Certificates PG_HIN HANG HO.png'
import bbCert from '../components/images/cert_aca/2009-03-16 UTS Cert_Bachelor.png'

import metavc from '../components/images/cert_ind/2023-09-07 Version Control.png'
import metarb from '../components/images/cert_ind/2023-08-21 React Basics.png'
import metahcd from '../components/images/cert_ind/2023-08-11  HTML and CSS in depth.png'
import metapwj from '../components/images/cert_ind/2023-07-20 Programming with JavaScript.png'
import umpfe from '../components/images/cert_ind/2023-06-29 Python for Everybody.png'
import metaifed from '../components/images/cert_ind/2023-06-15 Intro Front End.png'
import frm from '../components/images/cert_ind/2015-04-15 FRM.png'


export default function CertPreviewer({ certKey }) {
    let imgSrc = ""
    switch (certKey) {
        case 'mitCert':
            return <Image src={mitCert} />

        case 'mitTrans':
            return <Image src={mitTrans} />

        case 'mitDean2023':
            return <Image src={mitDean2023} />

        case 'mitDean2022':
            return <Image src={mitDean2022} />

        case 'bbCert':
            return <Image src={bbCert} />

        case 'metavc':
            return <Image src={metavc} />

        case 'metarb':
            return <Image src={metarb} />

        case 'metahcd':
            return <Image src={metahcd} />

        case 'metapwj':
            return <Image src={metapwj} />

        case 'umpfe':
            return <Image src={umpfe} />

        case 'metaifed':
            return <Image src={metaifed} />

        case 'frm':
            return <Image src={frm} />


        default:
            return <></>
            break;
    }
}
