
import './App.css';
import { useState, createContext } from 'react';
import { Container, Navbar, Nav, Offcanvas, Image } from 'react-bootstrap';
import Home from './pages/Home';
import Certificates from './pages/Certificates';


export const ContentKey = createContext(null)

export default function App() {
    const [contentKey, setContentKey] = useState('home')
    let displayContent = <></>
    switch (contentKey) {
        case 'home':
            displayContent = <Home />
            break;
        case 'cert':
            displayContent = <Certificates />
            break
        // case 'proj':
        //     displayContent = <Projects />
        //     break

        default:
            break;
    }
    return (
        <Container className='col-12 col-lg-10'>
            <Navbar
                sticky="top" bg="dark" data-bs-theme="dark"
                collapseOnSelect
                expand='md'
                className="mb-3 fixed-top shadow-lg"
            >
                <Navbar.Brand href='/'
                    className='d-flex align-items-center justify-content-between'
                    style={{ height: '50px' }}
                >
                    <Image
                        src="/favicon.png"
                        style={{ height: '100%', width: 'auto' }}
                    />{' '}
                    <b className='mx-2 fs-2'>Donald HO</b>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />

                <Navbar.Offcanvas aria-labelledby='offcanvasNavbarLabel-expand-sm' placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title
                            className="bg-light mb-3 shadow-lg"
                            style={{ height: '50px' }}
                        >
                            <h1>
                                <Image
                                    src="/favicon.png"
                                    style={{ height: '50px', width: 'auto' }}
                                />{'  '}
                                Donald's Bio
                            </h1>

                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Nav
                            className="justify-content-end flex-grow-1 pe-3"
                            onSelect={(selecteKey) => { setContentKey(selecteKey) }}
                        >
                            <Nav.Link eventKey='home'>Home</Nav.Link>
                            <Nav.Link eventKey='cert'>Certificates</Nav.Link>
                            <Nav.Link href='https://github.com/AchillesReaper'>My GitHub</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>


            </Navbar>
            {displayContent}

        </Container>
    );
}

