import { Container, Row, Col, Image } from "react-bootstrap";
import sf_html from './images/skill/sf-HTML.png'
import sf_css from './images/skill/sf-CSS.png'
import sf_js from './images/skill/sf-Javascript.png'
import sf_react from './images/skill/sf-react.png'
import sf_bs from './images/skill/sf-bootstrap.png'

import db_p from './images/skill/db-postgresql.png'
import db_m from './images/skill/db-mysql.png'

import c_aws from './images/skill/cloud-aws.png'
import c_gcp from './images/skill/cloud-gcp.png'

import vc_g from './images/skill/vc-git.png'
import vc_gh from './images/skill/vc-GitHub.png'


export default function Skill() {
    const tech_frontEnd = [sf_html, sf_css, sf_js, sf_react, sf_bs]
    const tech_database = [db_p, db_m]
    const tech_cloud = [c_aws, c_gcp]
    const tech_versionControl = [vc_g, vc_gh]

    return (
        <Container>
            <Row
                className="mx-2 mt-4 border-bottom border-white"
            >
                <h2>Core Skills</h2>
            </Row>

            <Row
                xs={1}
                className="mx-2 mb-3"
                style={{ textAlign: 'justify' }}
            >
                <Col className="mb-2" >
                    <h4>Front-end</h4>
                    {tech_frontEnd.map((tech, index) => {
                        return <Image key={`fe_${index}`} style={{ height: '60px', width: 'auto' }} src={tech} />
                    })}

                </Col>

                <Col className="mb-2">
                    <h4>Database</h4>
                    {tech_database.map((tech, index) => {
                        return <Image key={`db_${index}`} style={{ height: '60px', width: 'auto' }} src={tech} />
                    })}
                </Col>

                <Col className="mb-2">
                    <h4>Cloud</h4>
                    {tech_cloud.map((tech, index) => {
                        return <Image key={`c_${index}`} style={{ height: '60px', width: 'auto' }} src={tech} />
                    })}
                </Col>

                <Col className="mb-2">
                    <h4>Version Control</h4>
                    {tech_versionControl.map((tech, index) => {
                        return <Image key={`vc_${index}`} style={{ height: '60px', width: 'auto' }} src={tech} />
                    })}

                </Col>
            </Row>

        </Container>
    );
}


