import { Container, Row } from "react-bootstrap";

export default function Profile() {

    return (
        <Container>
            <Row
                className="mx-2 border-bottom border-white"
            >
                <h2>Career Profile</h2>
            </Row>

            <Row className="mx-2 mb-3" style={{ textAlign: 'justify' }}>
                <div>
                    <p>
                        Enthusiastic and motivated professional with software development skills seeking to pursue a long term career in software development.
                    </p>
                    <p>
                        Ability to critically think, problem solve and effectively communicate ideas and solutions.
                    </p>
                    <p>
                        Experienced in working with clients in fast-paced working environment.
                    </p>

                </div>
            </Row>

        </Container>
    );
}
