import { Container, Row } from "react-bootstrap";

export default function Education() {

    return (
        <Container>
            <Row
                className="mx-2 mt-4 border-bottom border-white"
            >
                <h2>Education</h2>
            </Row>
            <Row className="mx-2 mb-3">
                <h4> Master of Information Technology</h4>
                <h6><i>major in Software Development</i></h6>
                <h6>University of Techlonogy, Sydney</h6>
                <div>

                    02/2020 - 07/2023
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>GPA|6.88/7.00</b>

                    <ul>
                        <li> Dean List 2022</li>
                        <li> Dean List 2023</li>
                    </ul>
                </div>
            </Row>
        </Container>
    );
}
