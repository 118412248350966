import { Button, Table } from "react-bootstrap";
import { certs_ind } from "./data";

export default function CertInd({ setCertKey }) {

    return (
        <Table striped bordered hover variant="dark">
            <thead style={{ position: 'sticky', top: '0', zIndex: 1000 }}>
                <tr>
                    <th>Organisation</th>
                    <th>Course</th>
                    <th>Completion</th>
                </tr>
            </thead>
            <tbody>
                {certs_ind.map((cert) => {
                    return (
                        <tr key={cert.certKey}>
                            <td>{cert.organisation}</td>
                            <td>
                                <Button variant="link" onClick={() => { window.open(`${cert.info}`, '_blank') }}>
                                    {cert.course}
                                </Button>
                            </td>
                            <td>
                                <Button variant="link" onClick={() => setCertKey(`${cert.certKey}`)}>
                                    {cert.date}
                                </Button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    );
}
