import { Col, Container, Row } from "react-bootstrap";
import Education from "../components/Education";
import Profile from "../components/Profile";
import Skill from "../components/Skill";
import ProjectTimeline from "../components/ProjectTimeline";

export default function Home() {

    return (
        <Container>
            <Row
                className="text-white"
                style={{ backgroundColor: "#1a5775" }}>
                <Col>
                    <Education />
                    <Profile />
                </Col>
                <Col>
                    <Skill />
                </Col>
            </Row>
            <Row
                className="text-center mt-3 pt-3"
                style={{ backgroundColor: "#3da3d5" }}
                >
                <ProjectTimeline />
            </Row>
        </Container>
    );
}
