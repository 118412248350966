import { Container, Row, Tabs, Tab } from "react-bootstrap";
import { useState } from "react";
import CertAca from "../components/CertAcademic";
import CertPreviewer from "../components/CertPreviewer";
import CertInd from "../components/CertIndustry";

export default function Certificates() {
    const [certType, setCertType] = useState('aca')
    const [certKey, setCertKey] = useState(null)
    let certTable = <></>
    switch (certType) {
        case 'aca':
            certTable = <CertAca setCertKey={setCertKey} />
            break;

        case 'ind':
            certTable = <CertInd setCertKey={setCertKey} />

            break;

        default:
            break;
    }
    return (
        <Container>
            <Tabs
                fill
                variant="underline"
                onSelect={selectedKey => setCertType(selectedKey)}
            >
                <Tab title='Academic' eventKey='aca' > </Tab>
                <Tab title='Industrial' eventKey='ind'  > </Tab>


            </Tabs>
            <Row className="px-2 mt-2 border-primary rounded-3 text-center" style={{ maxHeight: '40vh', overflow: 'scroll' }}>
                {certTable}
            </Row>
            <Row>
                <CertPreviewer certKey={certKey}/>
            </Row>

        </Container>
    );
}