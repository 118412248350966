import { Button, Table } from "react-bootstrap";
import { certs_aca } from "./data";

export default function CertAca({ setCertKey }) {

    return (
        <Table striped bordered hover variant="dark">
            <thead style={{position:'sticky', top: '0', zIndex: 1000}}>
                <tr>
                    <th>University</th>
                    <th>Course</th>
                    <th>Testmonial</th>
                    <th>Year</th>
                </tr>
            </thead>
            <tbody>
                {certs_aca.map((cert) => {
                    return (
                        <tr key={cert.certKey}>
                            <td>{cert.uni}</td>
                            <td>{cert.course}</td>
                            <td>
                                <Button variant="link" onClick={() => setCertKey(`${cert.certKey}`)}>
                                    {cert.tesmonial}
                                </Button>
                            </td>
                            <td>{cert.year}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    );
}
