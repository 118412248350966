export const project_list = [
    {
        title: "Reserve Hub",
        tech: "TypeScript, React, Material-UI, Express, Node.js, GCP(Firestore, Firebase Hosting, Firebase Authentication, Cloud Function)",
        description: "A full-stack application allowing businesses to receive booking from their customers.",
        buttonText: "Production",
        date: "March 2024",
        icon: "project",
        refLink: "https://rs-hub.web.app/"
    },

    {
        title: "Shore Investment",
        tech: "JavaScript, TypeScript, React, Boostrap, localStorage, AWS(S3, Route53, CloudFront)",
        description: "A simple web app allowing users to manage a fake stock portfolio and a cash balance",
        buttonText: "Production",
        date: "December 2023",
        icon: "project",
        refLink: "https://investment-diary.dh3.space/"
    },

    {
        title: "ShunHing Stereo",
        tech: "JavaScript, React, Boostrap, CSS, AWS(S3, Route53, CloudFront)",
        description: "A web site for a Hong Kong business, showcasing their services and products.",
        buttonText: "Production",
        date: "November 2023",
        icon: "project",
        refLink: "https://shunhingstereo.com/"
    },

    {
        title: "Bio Page",
        tech: "JavaScript, React, Boostrap, react-vertical-timeline-component, AWS(S3, Route53, CloudFront)",
        description: "A bio page to showcase my projects, as well as my academic/traing certificates.",
        buttonText: "Production",
        date: "September 2023",
        icon: "project",
        refLink: "/bio"
    },

    {
        title: "UTS Environment",
        tech: "Processing, Processing Sounds and ControlP5, API, JSON, Python",
        description:
            "This is a UTS project. Visualizing multiple types of data collected by FEIT UTS with original design on interaction and presentation. ",
        buttonText: "Demo",
        date: "October 2022",
        icon: "project",
        refLink: 'https://youtu.be/wl4TmeNfV6s'
    },

    {
        title: "Better Health",
        tech: "Java, MySQL, JBDC, javax, batch scripting",
        description:
            "A simple bookkeeping system for a local business.",
        buttonText: "Demo",
        date: "August 2022",
        icon: "project",
        refLink: 'https://www.youtube.com/watch?v=S9te6TwZNVo'
    },


    {
        title: "Bubble Pop",
        tech: "Swift, StoryBoard",
        description: "A client-side-only iOS game",
        buttonText: "Demo",
        date: "May 2022",
        icon: "project",
        refLink: 'https://www.youtube.com/watch?v=PZ8vjW1kqTk'
    },

    {
        title: "Herts-UTS",
        tech: "HTML, CSS, JavaScript, JSON, AJAX, GItHub",
        description: "This is a UTS project. Mainly implement the shopping cart function for an online car rental platform.",
        buttonText: "Production",
        date: "May 2022",
        icon: "project",
        refLink: "https://achillesreaper.github.io/Car-Rental/"
    },

    {
        title: "Online Grocery Store",
        tech: "HTML, CSS, PHP, MySQL, JavaScript, AWS Beanstalk, GitHub",
        description: "This is a UTS project. An e-commerce web application with both server and client side",
        buttonText: "Demo",
        date: "May 2022",
        icon: "project",
        refLink: "https://www.youtube.com/embed/gNohrjJ1RSA?si=deyo8U2CdSQAXJcw"
    },

    {
        title: "Datamining in Action",
        tech: "Python, Jupyter Notebook, pandas, numpy, matplotlib, imblearn, sklearn",
        description: "This is a UTS project. This is an individual project for students acting as data scientists at a consulting company",
        buttonText: "Competetion Result",
        date: "May 2021",
        icon: "project",
        refLink: "https://www.kaggle.com/competitions/2021a-uts-data-analytics-assignment-3/leaderboard?tab=public"
    },

]

export const certs_aca = [
    {
        "uni": "University of Technology Sydney",
        "course": "Master of IT",
        "tesmonial": "Graduate Certificate",
        "year": 2023,
        "certKey": "mitCert"
    },

    {
        "uni": "University of Technology Sydney",
        "course": "Master of IT",
        "tesmonial": "Academic Transcript",
        "year": 2023,
        "certKey": "mitTrans"
    },

    {
        "uni": "University of Technology Sydney",
        "course": "Master of IT",
        "tesmonial": "Dean's List 2023",
        "year": 2023,
        "certKey": "mitDean2023"
    },

    {
        "uni": "University of Technology Sydney",
        "course": "Master of IT",
        "tesmonial": "Dean's List 2022",
        "year": 2022,
        "certKey": "mitDean2022"
    },

    {
        "uni": "University of Technology Sydney",
        "course": "Bachelor of Business",
        "tesmonial": "Graduate Certificate",
        "year": 2009,
        "certKey": "bbCert"
    },

]

export const certs_ind = [
    {
        "organisation": "Meta",
        "course": "Version Control",
        "date": "Sep 2023",
        "certKey": "metavc",
        "info": "https://www.coursera.org/learn/introduction-to-version-control/"
    },

    {
        "organisation": "Meta",
        "course": "React Basics",
        "date": "Aug 2023",
        "certKey": "metarb",
        "info": "https://www.coursera.org/learn/react-basics/"
    },

    {
        "organisation": "Meta",
        "course": "Programming with JavaScript",
        "date": "Aug 2023",
        "certKey": "metapwj",
        "info": "https://www.coursera.org/learn/programming-with-javascript/"
    },

    {
        "organisation": "Meta",
        "course": "HTML and CSS in depth",
        "date": "Aug 2023",
        "certKey": "metahcd",
        "info": "https://www.coursera.org/learn/html-and-css-in-depth/"
    },

    {
        "organisation": "Meta",
        "course": "Introduction to Front-End Development",
        "date": "Jun 2023",
        "certKey": "metaifed",
        "info": "https://www.coursera.org/learn/introduction-to-front-end-development/"
    },

    {
        "organisation": "University of Michigam",
        "course": "Python for Everybody",
        "date": "Jun 2023",
        "certKey": "umpfe",
        "info": "https://www.coursera.org/learn/python/"
    },

    {
        "organisation": "GARP",
        "course": "FRM",
        "date": "Apr 2015",
        "certKey": "frm",
        "info": "https://www.garp.org/frm"
    },
]
