import { ReactComponent as StartIcon } from "./images/icons/ti-start.svg"
import { ReactComponent as ProjectIcon } from './images/icons/ti-project.svg'

import "react-vertical-timeline-component/style.min.css"
import { Button, Container } from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { project_list } from "./data";

export default function ProjectTimeline() {

    return (
        <Container>
            <h1 className="text-white">IT Projects <StartIcon style={{ maxHeight: '60px', width: 'auto' }} /></h1>
            <VerticalTimeline>
                {project_list.map((element, index) => {

                    return (
                        <VerticalTimelineElement
                            key={index}
                            date={element.date}
                            dateClassName="date"
                            iconStyle={{ background: "#f49f0a" }}
                            icon={<ProjectIcon />}
                        >
                            <h3 className="vertical-timeline-element-title">
                                {element.title}
                            </h3>
                            <i className="vertical-timeline-element-subtitle">
                                {element.tech}
                            </i>
                            <p id="description">{element.description}</p>

                            <Button
                                variant="danger"
                                key={element.title}
                                onClick={() => window.open(`${element.refLink}`, "_blank")}
                            >
                            {element.buttonText}</Button>



                        </VerticalTimelineElement>
            )
                })}
        </VerticalTimeline>

        </Container >
    );
}
